import React from 'react'

//react-bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

//animation
import { motion } from 'framer-motion'
import { pageTransitions, pageVariants } from './pageTransitionStyles'

//react-router-dom
import { useHistory } from 'react-router-dom'

// translator
import { useTranslation } from 'react-i18next'

const HomePage = (props) => {

    const { t } = useTranslation()
    const history = useHistory()

    return (
        <motion.div
            initial='enter'
            animate='in'
            exit='exit'
            custom={props.dir}
            variants={pageVariants}
            transition={pageTransitions}
            id='home-container'
        >
            <div id='inner-home-container'>
                <Container className='py-5 page-container' fluid='md'>

                    <Row className='home-row'>
                        <Col>
                            <div>
                                <div id='home-text'>
                                    <h1>{`${t('welcome')} VENEZIANO S.R.L.`}</h1>
                                    <h3 className='mb-4'>{t('homeIntro')}</h3>
                                </div>
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    className='link-button'
                                    onClick={() => {
                                        props.setExitDir('l')
                                        history.push('/chi-siamo')}
                                    }
                                >
                                    {t('scopri')}
                                </motion.button>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
        </motion.div>
    )
}

export default HomePage