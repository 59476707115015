import React from 'react'

import Modal from 'react-bootstrap/Modal'

import Loader from 'react-loader-spinner'

//translator
import { useTranslation } from 'react-i18next'

const SendingModal = (props) => {

    //hooks
    const { t } = useTranslation()

    return(
        <Modal 
            show={props.show} 
            backdrop='static' 
            centered 
            size='sm'
        >
            <Modal.Body className='d-flex justify-content-center'>
                <div className='d-flex'>
                
                    <Loader
                        type='Watch'
                        color='#cc1616'
                        height={50}
                        width={50}
                    />
                    <span className='align-self-center ml-3'><h4>{t('inviando')}</h4></span>
                </div>
                
            </Modal.Body>
        </Modal>
    )
}

export default SendingModal