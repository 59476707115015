import React from 'react'

//react bootstrap
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

//font awesome
import { faAward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//translator
import { useTranslation } from 'react-i18next'

//components
import LinkButton from '../components/LinkButton'

//certificates images
import iso from '../assets/certificati/iso_logo.jpg'
import brc from '../assets/certificati/brc_logo.jpg'

//certificates pdf
import isoPdf from '../assets/certificati/iso.pdf'
import brcPdf from '../assets/certificati/brc.pdf'

//animation
import { motion } from 'framer-motion'
import { pageTransitions, pageVariants } from './pageTransitionStyles'

const QualitaPage = (props) => {

    //hooks
    const { t } = useTranslation()

    return (
        <Container className='py-5 page-container' fluid='md'>
            <motion.div
                initial='enter'
                animate='in'
                exit='exit'
                custom={props.dir}
                variants={pageVariants}
                transition={pageTransitions}
            >
                <Row>
                    <Col className='title pb-4'>
                        <h1>{t('impQualita')}</h1>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p>{t('introQual')}</p>
                    </Col>
                </Row>

                <Row>
                    <Col className='title pb-4 pt-5'>
                        <h1><FontAwesomeIcon icon={faAward} className='mr-2 icon-qual' />{t('certificati')}</h1>
                    </Col>
                </Row>

                <Row className='pb-2'>
                    <Col>
                        <p>{t('introCert')}</p>
                    </Col>
                </Row>

                <Row className='pt-4 pb-3 cert-row'>
                    <Col>
                        <h4>UNI EN ISO 9001:2015</h4>
                    </Col>
                </Row>

                <Row>
                    <Col className='d-flex align-items-center justify-content-center' md={12} lg={6}>
                        <Image src={iso} height='185' width='400' id='logo-iso' />
                    </Col>

                    <Col md={12} lg={6} className='pt-3 pt-lg-0'>
                        <p>
                            {`${t('iso1')} `}
                            <a href='https://www.iso.org/standard/62085.html' target='_blank' rel='noopener noreferrer'>ISO 9001:2015</a>
                            {` ${t('iso2')}`}
                            (<a href='https://www.bureauveritas.it/' target='_blank' rel='noopener noreferrer'>Bureau Veritas</a>),
                            {` ${t('iso3')}`}
                        </p>
                    </Col>

                </Row>

                <Row className='pt-2 pb-4'>
                    <Col className=' d-flex justify-content-center'>
                        <LinkButton
                            link={isoPdf}
                            text='vediPdf'
                        />
                    </Col>
                </Row>

                <Row className='pt-4 pb-3 cert-row'>
                    <Col>
                        <h4>BRC Global standard for Packaging materials</h4>
                    </Col>
                </Row>

                <Row>
                    <Col className='d-flex align-items-center justify-content-center' md={12} lg={6}>
                        <Image src={brc} height='185' width='246' id='logo-brc' />
                    </Col>

                    <Col md={12} lg={6} className='pt-3 pt-lg-0'>
                        <p>
                            {`${t('brc1')} `}
                            <a href='https://www.brcgs.com/our-standards/packaging-materials/' target='_blank' rel='noopener noreferrer'>
                                BRC Global standard (BRCGS) for Packaging materials
                            </a>
                            {`${t('brc2')} `}
                        </p>
                    </Col>

                </Row>

                <Row className='py-2'>
                    <Col className=' d-flex justify-content-center'>
                        <LinkButton
                            link={brcPdf}
                            text='vediPdf'
                        />
                    </Col>
                </Row>
            </motion.div>
        </Container>
    )
}

export default QualitaPage