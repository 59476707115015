import React, { useState } from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

const Gallery = (props) => {

    //state
    const [show, setShow] = useState(false)
    const [index, setIndex] = useState(0)

    const openImage = (num) => {
        setIndex(parseInt(num))
        setShow(true)
    }

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    }

    return (
        <>
            <Row className='pt-4'>
                <Col>
                    <Image
                        src='https://i.imgur.com/IUruIP9.jpg'
                        name='0'
                        fluid
                        className='gallery-image'
                        onClick={(ev) => props.openImage(ev.target.name)} />
                </Col>
                <Col>
                    <Image
                        src='https://i.imgur.com/DfmOGsg.jpg'
                        name='1'
                        fluid
                        className='gallery-image'
                        onClick={(ev) => props.openImage(ev.target.name)} />
                </Col>

                <Col>
                    <Image
                        src='https://i.imgur.com/vXPp1MQ.jpg'
                        name='2'
                        fluid
                        className='gallery-image'
                        onClick={(ev) => props.openImage(ev.target.name)} />
                </Col>

                <Col>
                    <Image
                        src='https://i.imgur.com/Sz7eLHd.jpg'
                        name='3'
                        fluid
                        className='gallery-image'
                        onClick={(ev) => props.openImage(ev.target.name)} />
                </Col>

                <Col>
                    <Image
                        src='https://i.imgur.com/jJyTucI.jpg'
                        name='4'
                        fluid
                        className='gallery-image'
                        onClick={(ev) => props.openImage(ev.target.name)} />
                </Col>
            </Row>

            <Row className='pt-4'>
                <Col>
                    <Image
                        src='https://i.imgur.com/5pRJbk8.jpg'
                        name='5'
                        fluid
                        className='gallery-image'
                        onClick={(ev) => props.openImage(ev.target.name)} />
                </Col>
                <Col>
                    <Image
                        src='https://i.imgur.com/79aSXpW.jpg'
                        name='6'
                        fluid
                        className='gallery-image'
                        onClick={(ev) => props.openImage(ev.target.name)} />
                </Col>

                <Col>
                    <Image
                        src='https://i.imgur.com/YmJatfC.jpg'
                        name='7'
                        fluid
                        className='gallery-image'
                        onClick={(ev) => props.openImage(ev.target.name)} />
                </Col>

                <Col>
                    <Image
                        src='https://i.imgur.com/I2cmjIG.jpg'
                        name='8'
                        fluid
                        className='gallery-image'
                        onClick={(ev) => props.openImage(ev.target.name)} />
                </Col>

                <Col>
                    <Image
                        src='https://i.imgur.com/YMiOyJv.jpg'
                        name='9'
                        fluid
                        className='gallery-image'
                        onClick={(ev) => props.openImage(ev.target.name)} />
                </Col>
            </Row>

            <Row className='pt-4 pb-3'>
                <Col>
                    <Image
                        src='https://i.imgur.com/qwzntks.jpg'
                        name='10'
                        fluid
                        className='gallery-image'
                        onClick={(ev) => props.openImage(ev.target.name)} />
                </Col>
                <Col>
                    <Image
                        src='https://i.imgur.com/dbx4YMB.jpg'
                        name='11'
                        fluid
                        className='gallery-image'
                        onClick={(ev) => props.openImage(ev.target.name)} />
                </Col>

                <Col>
                    <Image
                        src='https://i.imgur.com/TkMmaZG.jpg'
                        name='12'
                        fluid
                        className='gallery-image'
                        onClick={(ev) => props.openImage(ev.target.name)} />
                </Col>

                <Col>
                    <Image
                        src='https://i.imgur.com/COVg9dG.jpg'
                        name='13'
                        fluid
                        className='gallery-image'
                        onClick={(ev) => props.openImage(ev.target.name)} />
                </Col>

                <Col>
                    <Image
                        src='https://i.imgur.com/UCr4NDy.jpg'
                        name='14'
                        fluid
                        className='gallery-image'
                        onClick={(ev) => props.openImage(ev.target.name)} />
                </Col>
            </Row>
        </>
    )
}

export default Gallery