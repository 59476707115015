import React from 'react'

// react bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

//components
import ContattaciForm from '../components/ContattaciForm'
import Map from '../components/Map'

//font awesome
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// translator
import { useTranslation } from 'react-i18next'

//animation
import { motion } from 'framer-motion'
import { pageTransitions, pageVariants } from './pageTransitionStyles'


const ContattaciPage = (props) => {

    //hooks
    //translation
    const { t } = useTranslation()


    return (
        <Container className='py-5 page-container' fluid='md'>
            <motion.div
                initial='enter'
                animate='in'
                exit='exit'
                custom={props.dir}
                variants={pageVariants}
                transition={pageTransitions}
            >
                <Row>
                    <Col className='title pb-5'>
                        <h1>{t('contattaci')}</h1>
                        <p className='sotto-titolo'>{t('contattaciSottotitolo')}</p>
                    </Col>
                </Row>

                <Row>
                    <Col lg={6} className='pb-3 pb-lg-0'>
                        <div className='info-contact big'>
                            <FontAwesomeIcon icon={faMapMarkerAlt} size='3x' id='map-marker-icon' />
                            <h3>{t('nostroIndirizzo')}</h3>
                            <p>{`Viale Kennedy 32, Siano (SA), 84088, ${t('italia')}`}</p>
                        </div>
                    </Col>

                    <Col lg={3} sm={6} className='pb-3 pb-sm-0'>
                        <div className='info-contact'>
                            <FontAwesomeIcon icon={faEnvelope} size='3x' className='contact-icon' />
                            <h3>{t('mandaMail')}</h3>
                            <p className='mb-1'>info@venezianosrl.it</p>
                            <p>logistica@venezianosrl.it</p>
                        </div>
                    </Col>

                    <Col lg={3} sm={6}>
                        <div className='info-contact'>
                            <FontAwesomeIcon icon={faPhoneAlt} size='3x' className='contact-icon' />
                            <h3>{t('chiamaci')}</h3>
                            <p className='mb-1'>+39 081 5182870</p>
                            <p>+39 081 5183705</p>
                        </div>
                    </Col>
                </Row>

                <Row className='pt-4'>

                    <Col lg={6} className='d-flex flex-column justify-content-start pb-4 pb-lg-0'>
                        <Map />
                    </Col>

                    <Col lg={6}>
                        <ContattaciForm />
                    </Col>

                </Row>
            </motion.div>
        </Container>


    )
}


export default ContattaciPage