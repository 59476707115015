import React from 'react'

//react bootstrap
import Modal from 'react-bootstrap/Modal'

// translator
import { useTranslation } from 'react-i18next'

//animation
import { motion } from 'framer-motion'

//font awesome
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const EmailModal = (props) => {

    const { t } = useTranslation()
    return (
        <Modal 
            show={props.show} 
            onHide = {props.close}
        >
            <Modal.Header className='d-flex justify-content-center'>
                {props.success && <FontAwesomeIcon icon={faCheckCircle} size='3x' className='success-modal-icon'/>  }
                {!props.success && <FontAwesomeIcon icon={faTimesCircle} size='3x' className='error-modal-icon'/>  }
            </Modal.Header>
            <Modal.Body>
                {props.success && t('emailInviata')}
                {!props.success && t('emailFallita')}
            </Modal.Body>
            <Modal.Footer>
                <motion.button 
                    className='shadow-none modal-button'
                    onClick={props.close}
                    whileHover={{ scale: 1.1 }}
                >
                    Ok
                </motion.button>
            </Modal.Footer>
        </Modal>
    )
}

export default EmailModal