import React, {useState} from 'react'

// react bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

// translator
import { useTranslation } from 'react-i18next'

//animation
import { motion } from 'framer-motion'
import { pageTransitions, pageVariants } from './pageTransitionStyles'

//components
import Gallery from '../components/Gallery'
import GalleryModal from '../components/GalleryModal'

const ChiSiamoPage = (props) => {

    //translation
    const { t } = useTranslation()

    //state
    const [show, setShow] = useState(false)
    const [index, setIndex] = useState(0)

    const openImage = (num) => {
        setIndex(parseInt(num))
        setShow(true)
    }

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    }

    return (
        <Container className='py-5 page-container' fluid='md'>
            <motion.div
                initial='enter'
                animate='in'
                exit='exit'
                custom={props.dir}
                variants={pageVariants}
                transition={pageTransitions}
            >
                <Row>
                    <Col className='title pb-4'>
                        <h1>{t('chiSiamo')}</h1>
                    </Col>
                </Row>

                <Row>
                    <Col className='title'>
                        <p>{t('chiSiamo1')}</p>
                        <p>{t('chiSiamo2')}</p>
                        <p>{t('chiSiamo3')}</p>
                        <p>{t('chiSiamo4')}</p>
                    </Col>
                </Row>

                <Row>
                    <Col className='title pb-4 pt-5'>
                        <h1>{t('foto')}</h1>
                    </Col>
                </Row>

                <Gallery openImage={openImage} index={index} handleSelect={handleSelect}/>
                <GalleryModal show={show} setShow={setShow} index={index} handleSelect={handleSelect}/>
            </motion.div>
        </Container>

    )
}



export default ChiSiamoPage