import React from 'react'

// translator
import { useTranslation } from 'react-i18next'

//font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import { motion } from 'framer-motion'
const LinkButton = (props) => {

    //hooks
    //translation
    const { t } = useTranslation()

    return (
        <motion.a
            className='link-button'
            whileHover={{ scale: 1.1 }}
            href={props.link}
            target='_blank'
            rel='noopener noreferrer'
        >
            {t(props.text)}
            <FontAwesomeIcon icon={faFilePdf} className='ml-2' />
        </motion.a>
    )
}

export default LinkButton