import React from 'react'

// react bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

// translator
import { useTranslation } from 'react-i18next'

//components
import ListaBarattoli from '../components/ListaBarattoli'

//animation
import { motion } from 'framer-motion'
import { pageTransitions, pageVariants } from './pageTransitionStyles'

//video player
import ReactPlayer from 'react-player/vimeo'

//api
import API from '../api/API'

const ProdottiPage = (props) => {

    //hooks
    //translation
    const { t } = useTranslation()

    return (
        <Container className='py-5 page-container' fluid='md'>
            <motion.div
                initial='enter'
                animate='in'
                exit='exit'
                custom={props.dir}
                variants={pageVariants}
                transition={pageTransitions}
            >
                <Row>
                    <Col className='title pb-5'>
                        <h1>{t('titoloProd')}</h1>
                    </Col>
                </Row>

                <Row className='pb-4 justify-content-md-center'>
                    <Col xs={12} lg={4} xl={5} className='justify-content-center'>
                        <p>{t('prodIntro')}</p>
                    </Col>

                    <Col className='d-flex justify-content-center'>
                        <ReactPlayer
                            id='video'
                            url='https://vimeo.com/540089903'
                            light={true}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col className='title pb-5 pt-2'>
                        <h1>{t('catalogo')}</h1>
                    </Col>
                </Row>

                <Row className='justify-content-sm-center justify-content-lg-start'>
                    <ListaBarattoli barattoli={props.barattoli} lang={props.lang} />
                </Row>
            </motion.div>
        </Container>
    )
}

export default ProdottiPage