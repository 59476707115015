import React, { useState } from 'react'

//react bootstrap
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

//font awesome
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// translator
import { useTranslation } from 'react-i18next'

//components
import SubmitButton from './SubmitButton'
import EmailModal from './EmailModal'
import SendingModal from './SendingModal'

//API
import API from '../api/API'

const ContattaciForm = () => {

    //hooks

    //translation
    const { t } = useTranslation()

    //state
    const [name, setName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [nameError, setNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [objectError, setObjectError] = useState('')
    const [messageError, setMessageError] = useState('')

    const [show, setShow] = useState(false)
    const [success, setSuccess] = useState(true)
    const [sending, setSending] = useState(false)

    // handle form fields change (controlled components)
    const handleChange = (ev) => {
        ev.preventDefault()

        switch (ev.target.id) {
            case 'name':
                setName(ev.target.value)
                break;
            case 'userEmail':
                setUserEmail(ev.target.value)
                break;
            case 'subject':
                setSubject(ev.target.value)
                break;
            case 'message':
                setMessage(ev.target.value)
                break;
        }
    }

    //validate fields after focus on them
    const checkOnBlur = (id, value) => {
        switch (id) {
            case 'name':
                !value && setNameError('nameErr')
                break;
            case 'userEmail':
                if (!value)
                    setEmailError('emailErrMissing')
                else if (!checkEmail())
                    setEmailError('emailErrInvalid')
                break;
            case 'subject':
                !value && setObjectError('objectErr')
                break;
            case 'message':
                !value && setMessageError('messageErr')
                break;
        }
    }

    // remove errors when focus is on fields
    const clearOnFocus = (id) => {
        switch (id) {
            case 'name':
                nameError && setNameError('')
                break;
            case 'userEmail':
                emailError && setEmailError('')
                break;
            case 'subject':
                objectError && setObjectError('')
                break;
            case 'message':
                messageError && setMessageError('')
                break;
        }
    }

    //check if text is valid email
    const checkEmail = () => {
        const validEmailRegex =
            RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)
        return validEmailRegex.test(userEmail)
    }

    //form validation on submit
    const validateForm = () => {

        let currentNameError
        let currentEmailError
        let currentObjectError
        let currentMessageError

        //check fields are not empty
        currentNameError = !name ? 'nameErr' : ''
        currentEmailError = !userEmail ? 'emailErrMissing' : ''
        currentObjectError = !subject ? 'objectErr' : ''
        currentMessageError = !message ? 'messageErr' : ''

        //check email
        currentEmailError = (!currentEmailError && !checkEmail()) ? 'emailErrInvalid' : currentEmailError

        //update state
        if (currentNameError)
            setNameError(currentNameError)
        if (currentEmailError)
            setEmailError(currentEmailError)
        if (currentObjectError)
            setObjectError(currentObjectError)
        if (currentMessageError)
            setMessageError(currentMessageError)

        return !currentNameError && !currentEmailError && !currentObjectError && !currentMessageError
    }

    //form clearing
    const clearForm = () => {
        setName('')
        setSubject('')
        setUserEmail('')
        setMessage('')
    }

    //form submission
    const submitForm = (ev) => {
        ev.preventDefault()

        if (validateForm()){
            const formData = {
                name: name.trim(),
                email: userEmail.trim(),
                subject: subject.trim(),
                message: message.trim()
            }
            setSending(true)
            API.sendEmail(formData)
            .then(() => {
                clearForm()
                setSending(false)
                setSuccess(true)
                setShow(true)
            })
            .catch(() =>{
                setSending(false)
                setSuccess(false)
                setShow(true)
            })
        }
            
        else
            console.log('invalid')
    }

    return (
        <>
            <Form
                id='form'
                onSubmit={(ev) => submitForm(ev)}
            >
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Control
                            type='text'
                            placeholder={t('tuoNome')}
                            id='name'
                            value={name}
                            onChange={(ev) => handleChange(ev)}
                            onFocus={(ev) => clearOnFocus(ev.target.id)}
                            onBlur={(ev) => checkOnBlur(ev.target.id, ev.target.value)}
                            className={nameError ? 'invalid-input' : ''}
                        />
                        {nameError && <Form.Text className='invalid-message'>
                            <FontAwesomeIcon icon={faExclamationCircle} className='mr-1' />{t(nameError)}
                        </Form.Text>}
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Control
                            type='text'
                            placeholder={t('tuaEmail')}
                            id='userEmail'
                            value={userEmail}
                            onChange={(ev) => handleChange(ev)}
                            onFocus={(ev) => clearOnFocus(ev.target.id)}
                            onBlur={(ev) => checkOnBlur(ev.target.id, ev.target.value)}
                            className={emailError ? 'invalid-input' : ''}
                        />
                        {emailError
                            &&
                            <Form.Text className='invalid-message'>
                                <FontAwesomeIcon icon={faExclamationCircle} className='mr-1' />{t(emailError)}
                            </Form.Text>}
                    </Form.Group>
                </Form.Row>

                <Form.Group>
                    <Form.Control
                        type='text'
                        placeholder={t('oggetto')}
                        id='subject'
                        value={subject}
                        onChange={(ev) => handleChange(ev)}
                        onFocus={(ev) => clearOnFocus(ev.target.id)}
                        onBlur={(ev) => checkOnBlur(ev.target.id, ev.target.value)}
                        className={objectError ? 'invalid-input' : ''}
                    />
                    {
                        objectError
                        &&
                        <Form.Text className='invalid-message'>
                            <FontAwesomeIcon icon={faExclamationCircle} className='mr-1' />{t(objectError)}
                        </Form.Text>
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Control
                        as='textarea'
                        placeholder={t('testo')}
                        id='message'
                        value={message}
                        onChange={(ev) => handleChange(ev)}
                        onFocus={(ev) => clearOnFocus(ev.target.id)}
                        onBlur={(ev) => checkOnBlur(ev.target.id, ev.target.value)}
                        className={messageError ? 'invalid-input' : ''}
                        rows={5}
                    />
                    {messageError
                        &&
                        <Form.Text className='invalid-message'>
                            <FontAwesomeIcon icon={faExclamationCircle} className='mr-1' />{t(messageError)}
                        </Form.Text>}
                </Form.Group>

                <Form.Row className='d-flex justify-content-center'>
                        <SubmitButton
                            nameError={nameError}
                            emailError={emailError}
                            objectError={objectError}
                            messageError={messageError}
                        />
                </Form.Row>

            </Form>

            <EmailModal 
                show={show} 
                close={() => setShow(false)}
                success={success}
            />

            <SendingModal show={sending}/>
        </>
    )
}


export default ContattaciForm