import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationIT from './languages/it/translationIT.json'
import translationEN from './languages/en/translationEN.json'

//the translations
const resources = {
    en: {
        translation: translationEN
    },
    it: {
        translation: translationIT
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'it',

        keySeparator: false,

        interpolation: {
            escapeValue: false
        }
    })

    export default i18n