import React from 'react'

// translator
import { useTranslation } from 'react-i18next'

//components 
import DirectionsLink from './DirectionsLink'

const MapInfo = () => {

    //translation
    const { t } = useTranslation()

    return (

        <div className='map-info d-flex'>
            <div className='pr-1'>
                <div className='map-info-title'>Veneziano s.r.l.</div>
                <div className='map-info-text'>{`Viale Kennedy 32, Siano (SA), 84088, ${t('italia')}`}</div>
            </div>
            <DirectionsLink/>
        </div>
    )
}

export default MapInfo