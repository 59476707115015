import React, { useState } from 'react'

//react router dom
import { NavLink, useLocation } from 'react-router-dom'

//react bootstrap
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Dropdown from 'react-bootstrap/Dropdown'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Image from 'react-bootstrap/Image'

// translator
import { useTranslation } from 'react-i18next'
import i18next from '../i18n'

//components
import Flag from './Flag'

//images
import logo from '../assets/loghi/logoNavbar.png'

//custom hooks
import useWindowDimension from '../hooks/useWindowDimension'

const MyNavbar = (props) => {

    //hooks
    const { t } = useTranslation()
    const [expanded, setExpanded] = useState(false)
    const currentPath = useLocation();

    const { width } = useWindowDimension()

    // dropdown change language function
    const changeLan = (newLan) => {
        i18next.changeLanguage(newLan)
        props.setLanguage(newLan)
    }

    const onLinkClick = (newPath) => {
        setExpanded(false)
        let currentPathNum
        switch (currentPath.pathname) {
            case '/':
                currentPathNum = 1
                break
            case '/chi-siamo':
                currentPathNum = 2
                break
            case '/prodotti':
                currentPathNum = 3
                break
            case '/qualita':
                currentPathNum = 4
                break
            case '/contattaci':
                currentPathNum = 5
                break
            default:
                currentPathNum = 1
        }

        if (newPath !== currentPathNum) {
            (newPath > currentPathNum) ? props.setExitDir('l') : props.setExitDir('r')
        }
    }

    return (
        <header className='fixed-top header'>
            <Container fluid='md'>
                <Navbar id='navigation-bar' expand="lg" expanded={expanded} className='px-0'>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />

                    <Navbar.Brand href='/' className='py-0'>
                        <Image id='nav-logo' src={logo} height='80' width='153' />
                    </Navbar.Brand>

                    {width < 992
                        &&
                        <div className='d-flex flex-column justify-content-around'>
                            <NavDropdown title={<CurrentLan lan={props.lan} />} id="language-dropdown" alignRight>
                                <Dropdown.Item
                                    as="button"
                                    value='en'
                                    onClick={(ev) => changeLan(ev.currentTarget.value)}
                                    active={props.lan.localeCompare('en') === 0}
                                >
                                    <Flag country='GB' />
                                        English
                                    </Dropdown.Item>
                                <Dropdown.Item
                                    as="button"
                                    value='it'
                                    onClick={(ev) => changeLan(ev.currentTarget.value)}
                                    active={props.lan.localeCompare('it') === 0}
                                >
                                    <Flag country='IT' />
                                            Italiano
                                    </Dropdown.Item>
                            </NavDropdown>
                        </div>
                    }

                    <Navbar.Collapse id="basic-navbar-nav" className='d-lg-flex justify-content-lg-end'>
                        <Nav id='menu'>
                            <Nav.Link
                                id='1'
                                exact to="/"
                                as={NavLink}
                                onClick={(ev) => onLinkClick(ev.target.id)}
                            >
                                Home
                            </Nav.Link>
                            <Nav.Link
                                id='2'
                                to="/chi-siamo"
                                as={NavLink}
                                onClick={(ev) => onLinkClick(ev.target.id)}
                            >
                                {t('chiSiamo')}
                            </Nav.Link>
                            <Nav.Link
                                id='3'
                                to="/prodotti"
                                as={NavLink}
                                onClick={(ev) => onLinkClick(ev.target.id)}
                            >
                                {t('prodotti')}
                            </Nav.Link>
                            <Nav.Link
                                id='4'
                                to="/qualita"
                                as={NavLink}
                                onClick={(ev) => onLinkClick(ev.target.id)}
                            >
                                {t('qualita')}
                            </Nav.Link>
                            <Nav.Link
                                id='5'
                                to="/contattaci"
                                as={NavLink}
                                onClick={(ev) => onLinkClick(ev.target.id)}
                            >
                                {t('contattaci')}
                            </Nav.Link>
                        </Nav>

                        {width >= 992
                            &&
                            <NavDropdown title={<CurrentLan lan={props.lan} />} id="language-dropdown">
                                <Dropdown.Item
                                    as="button"
                                    value='en'
                                    onClick={(ev) => changeLan(ev.currentTarget.value)}
                                    active={props.lan.localeCompare('en') === 0}
                                >
                                    <Flag country='GB' />
                                English
                            </Dropdown.Item>
                                <Dropdown.Item
                                    as="button"
                                    value='it'
                                    onClick={(ev) => changeLan(ev.currentTarget.value)}
                                    active={props.lan.localeCompare('it') === 0}
                                >
                                    <Flag country='IT' />
                                    Italiano
                            </Dropdown.Item>

                            </NavDropdown>
                        }

                    </Navbar.Collapse>

                </Navbar>
            </Container>
        </header>
    )
}


// mostra la bandiera della lingua attualmente usata
const CurrentLan = (props) => {
    let countryCode
    let countryName
    switch (props.lan) {
        case 'it':
            countryCode = 'IT'
            countryName = 'Italiano'
            break
        case 'en':
            countryCode = 'GB'
            countryName = 'English'
            break
        default:
            countryName = 'Italiano'
    }
    return (
        <>
            <Flag country={countryCode} />
            {countryName}
        </>
    )
}

export default MyNavbar