class Barattolo {
    constructor(id, name, diameter, height, datasheet, image){
        this.id = id
        this.name = name
        this.diameter = diameter
        this.height = height
        this.datasheet = datasheet
        this.image=image
    }

    static fromJson(json){
        const temp =  Object.assign(new Barattolo(), json);
        return temp;
    }
}

export default Barattolo