import './App.css';
import React, { useState, useEffect } from 'react'

//react-router-dom
import { Switch, Route, withRouter, useLocation } from 'react-router-dom'

//Bootstrap
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

//components
import Navbar from './components/Navbar'
import Footer from './components/Footer'

//pages
import ChiSiamoPage from './pages/ChiSiamoPage'
import ContattaciPage from './pages/ContattaciPage'
import QualitaPage from './pages/QualitaPage'
import HomePage from './pages/HomePage'
import ProdottiPage from './pages/ProdottiPage'
import NotFoundPage from './pages/NotFoundPage'

//transitions
import { AnimatePresence } from 'framer-motion'

//api
import API from './api/API'

const App = () => {

  //state
  const [language, setLanguage] = useState('it')
  const [exitDir, setExitDir] = useState('l')
  const [barattoli, setBarattoli] = useState([])

  const location = useLocation()

  // come componentDidMount
  useEffect(() => {
    API.getBarattoli()
      .then((listaBarattoli) => {
        setBarattoli([...listaBarattoli])
      })
      .catch(() => {

      })
  }, [])

  return (
    <>
      <Navbar lan={language} setLanguage={setLanguage} setExitDir={setExitDir} />

      <AnimatePresence exitBeforeEnter initial={false} custom={exitDir}>
        <Switch location={location} key={location.pathname}>

          <Route exact path='/chi-siamo'>
            <ChiSiamoPage dir={exitDir} />
          </Route>

          <Route exact path='/prodotti'>
            <ProdottiPage dir={exitDir} barattoli={barattoli} lang={language} />
          </Route>

          <Route exact path='/qualita'>
            <QualitaPage dir={exitDir} />
          </Route>

          <Route exact path='/contattaci'>
            <ContattaciPage dir={exitDir} />
          </Route>

          <Route exact path='/'>
            <HomePage dir={exitDir} setExitDir={setExitDir}/>
          </Route>

          <Route>
            <NotFoundPage />
          </Route>

        </Switch>
      </AnimatePresence>


      <Footer setExitDir={setExitDir} />
    </>
  );
}

export default withRouter(App)