import React from 'react'

//react bootstrap
import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'
import Carousel from 'react-bootstrap/Carousel'

const GalleryModal = (props) => {
    const pictures = [
        'https://i.imgur.com/IUruIP9.jpg',
        'https://i.imgur.com/DfmOGsg.jpg',
        'https://i.imgur.com/vXPp1MQ.jpg',
        'https://i.imgur.com/Sz7eLHd.jpg',
        'https://i.imgur.com/jJyTucI.jpg',

        'https://i.imgur.com/5pRJbk8.jpg',
        'https://i.imgur.com/79aSXpW.jpg',
        'https://i.imgur.com/YmJatfC.jpg',
        'https://i.imgur.com/I2cmjIG.jpg',
        'https://i.imgur.com/YMiOyJv.jpg',
        
        'https://i.imgur.com/qwzntks.jpg',
        'https://i.imgur.com/dbx4YMB.jpg',
        'https://i.imgur.com/TkMmaZG.jpg',
        'https://i.imgur.com/COVg9dG.jpg',
        'https://i.imgur.com/UCr4NDy.jpg'
        
    ]

    const prevIcon = 
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-chevron-double-left" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            <path fillRule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
        </svg>
    
    const nextIcon = 
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
            <path fillRule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
        </svg>
    
    const xIcon = 
        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="25" 
        height="25" 
        fill="currentColor" 
        className="bi bi-x-circle-fill close-modal" 
        viewBox="0 0 16 16"
        onClick={() => props.setShow(false)}>
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
        </svg>
    return (
        <Modal
            show={props.show}
            onHide={() => props.setShow(false)}
            centered
            size='lg'>
            <Modal.Body>
                <Carousel  
                activeIndex={props.index}
                onSelect={props.handleSelect}
                interval={null}
                prevLabel={null}
                prevIcon={prevIcon}
                nextLabel={null}
                nextIcon={nextIcon}
                indicators={false}>
                    {pictures.map((picture, ind) => {
                        return (
                            <Carousel.Item key={ind}>
                                <div className='d-flex justify-content-center'>
                                    <Image src={picture} fluid className='carousel-image'/>
                                </div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </Modal.Body>

            {xIcon}

        </Modal>
    )
}

export default GalleryModal