import React from 'react'

//font awesome
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const LocationPin = (props) => (
    <div className="d-flex pin">
      <FontAwesomeIcon icon={faMapMarkerAlt} size='3x' className='map-icon mr-1'/>
      <p className="pin-text align-self-center mb-0">{props.text}</p>
    </div>
  )

export default LocationPin