const pageVariants = {
    in: {
        opacity: 1,
        x: 0
    },
    exit: (custom) => ({
        opacity: 0,
        x: custom === 'r' ? '100vw' : '-100vw'
      }),
    enter: (custom) => ({
        opacity: 1,
        x: custom === 'r' ? '-100vw' : '100vw'
    })
  }

const pageTransitions = {
    transition: 'linear',
    duration: 0.6
}

export { pageTransitions, pageVariants }