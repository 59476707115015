import React from 'react'

//google map react
import GoogleMapReact from 'google-map-react'

//components
import LocationPin from './LocationPin'
import MapInfo from './MapInfo'

const Map = () => {

    const location = {
        address: 'Viale Kennedy 32, Siano (SA), 84088',
        lat: 40.797596,
        lng: 14.702397
    }

    return (

        <div className='map'>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyA4H73hwrI9aKnqqVkDX3NJd_O0eRIfUSE' }}
                defaultCenter={location}
                defaultZoom={15}
            >
                <LocationPin
                    lat={40.797596}
                    lng={14.702397}
                    text='Veneziano s.r.l.'
                />
            </GoogleMapReact>
            <MapInfo />
        </div>

    )
}

export default Map