import React from 'react'

// react bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

// translator
import { useTranslation } from 'react-i18next'

//font awesome
import { faFrown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//animation
import { motion } from 'framer-motion'

//react-router-dom
import { useHistory  } from 'react-router-dom'

const NotFoundPage = () => {

    const { t } = useTranslation()
    const history = useHistory()

    return (
        <Container className='py-5 page-container' fluid='md'>
            <Row>
                <Col className='d-flex justify-content-center'>
                    <FontAwesomeIcon icon={faFrown} size='10x' className='mb-3'/>
                </Col>
            </Row>
            <Row>
                <Col className='d-flex justify-content-center'>
                    <h1 className='not-found-title'>404</h1>
                </Col>
            </Row>
            <Row>
                <Col className='d-flex justify-content-center'>
                    <h2>{t('notFound')}</h2>
                </Col>
            </Row>
            <Row>
                <Col className='d-flex justify-content-center mt-2'>
                    <h5>{t('notFoundText')}</h5>
                </Col>
            </Row>
            <Row>
                <Col className='d-flex justify-content-center mt-2'>
                    <motion.button
                         whileHover={{ scale: 1.1 }} 
                         className='link-button'
                         onClick={() => history.push('/')}
                    >
                        {t('backHome')}
                    </motion.button>
                </Col>
            </Row>
        </Container>
    )
}

export default NotFoundPage