import React from 'react'

//react bootstrap
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button'

// translator
import { useTranslation } from 'react-i18next'

import { motion } from 'framer-motion'

const SubmitButton = (props) => {

    //translation
    const { t } = useTranslation()

    if (!props.nameError && !props.emailError && !props.objectError && !props.messageError)
        return (
            <motion.button
                id="sendButton"
                type="submit"
                className='shadow-none'
                whileHover={{ scale: 1.1 }}
            >
                {t('inviaMess')}
            </motion.button>
        )
    else
        return (
            <OverlayTrigger
                delay={{ show: 250, hide: 400 }}
                overlay=
                {<Popover>
                    <Popover.Content>
                        {t('popErrMessage')}
                        <ul>
                        {props.nameError && <li>{t('nome')}</li>}
                        {props.emailError && <li>Email</li>}
                        {props.objectError && <li>{t('oggetto')}</li>}
                        {props.messageError && <li>{t('testo')}</li>}
                        </ul>
                        
                    </Popover.Content>
                </Popover>}
            >
                <span className="d-inline-block">
                    <Button
                        className='shadow-none'
                        id="sendButton"
                        type="submit"
                        disabled
                    >
                        {t('inviaMess')}
                    </Button>
                </span>

            </OverlayTrigger>
        )
}

export default SubmitButton