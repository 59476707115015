import React from 'react'

//react-bootstrap
import Image from 'react-bootstrap/Image'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

// translator
import { useTranslation } from 'react-i18next'

//components
import DownloadButton from './DownloadButton'

const ContainerBarattolo = (props) => {

    const { t } = useTranslation()
    return(
        <Col sm={10} md={10} lg={6} className='container-barattolo'>
            <Row className='product-container mx-0 mx-md-1'>
                <Col sm={12} md='auto' className=' pb-3 pb-md-0 d-flex justify-content-center'>
                    <Image height={220} width={220} src={props.image}/>
                </Col>
                
                <Col>
                    <div className='d-md-flex flex-md-column justify-content-md-between h-100 mt-0'>
                        <div className='testo-prodotto'>
                            <h2 className='mb-3'>{props.name}</h2>
                            <p>
                                <strong>{`${t('diametro')}: `}</strong>{`${props.diameter} mm`}<br/>
                                <strong>{`${t('altezza')}: `}</strong>{`${props.height} mm`}<br/>
                            </p>
                        </div>
                        <div className='d-flex justify-content-center justify-content-md-end mt-2'>
                            <DownloadButton
                                link={props.sheet}
                                text='scheda'
                                name={props.sheetName}
                            />
                        </div>
                    
                    </div>
                </Col>
            </Row>
        </Col>
    )
}

export default ContainerBarattolo