import React, { useEffect } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

// translator
import { useTranslation } from 'react-i18next'

//react router dom
import { NavLink, useLocation } from 'react-router-dom'

//font awesome
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//images
import logo from '../assets/loghi/logoFooter.jpg'

const Footer = (props) => {

    //hooks
    const { t } = useTranslation()

    // Scroll top when location changes
    const location = useLocation(); 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const onLinkClick = (newPath) => {
        let currentPathNum 
        switch(location.pathname){
            case '/':
                currentPathNum = 1
                break
            case '/chi-siamo':
                currentPathNum = 2
                break
            case '/prodotti':
                currentPathNum = 3
                break
            case '/qualita':
                currentPathNum = 4
                break
            case '/contattaci':
                currentPathNum = 5
                break
            default:
                currentPathNum = 1
        }

        if(newPath !== currentPathNum){
            (newPath > currentPathNum) ? props.setExitDir('l') : props.setExitDir('r')
        }
    }

    return (
        <footer className='footer pt-4 pb-3'>
            <Container fluid='md'>
                <Row className='pb-4'>

                    <Col className='d-flex pb-lg-0 pb-4' lg={3} sm={6}>
                        <Image src={logo} height='124' width='234' className='align-self-center' />
                    </Col>

                    <Col lg={3} sm={6} className='pb-lg-0 pb-4' >
                        <h4 className='mb-3'>{t('indirizzo')}</h4>

                        <span className='d-flex'>
                            <FontAwesomeIcon icon={faMapMarkerAlt} size='2x' className='footer-icon-marker mr-2 align-self-center' />
                            <p className='align-self-center mb-0'>
                                Viale Kennedy, 32<br />{`84088, Siano (SA), ${t('italia')}`}
                            </p>
                        </span>
                    </Col>

                    <Col lg={3} sm={6} className='pb-lg-0 pb-4'>
                        <h4 className='mb-3'>{t('contatti')}</h4>

                        <span className='d-flex mb-2'>
                            <FontAwesomeIcon icon={faPhoneAlt} size='2x' className='footer-icon mr-2 align-self-center' />
                            <p className='align-self-center mb-0'>
                                +39 081 5182870<br />+39 081 5183705
                            </p>
                        </span>

                        <span className='d-flex'>
                            <FontAwesomeIcon icon={faEnvelope} size='2x' className='footer-icon mr-2 align-self-center' />
                            <p className='align-self-center mb-0'>info@venezianosrl.it</p>
                        </span>
                    </Col>

                    <Col lg={3} sm={6}>
                        <h4 className='text-white mb-3'>{t('linkUtili')}</h4>
                        <div className='d-flex flex-column'>
                            <NavLink id='1' className='footer-link' exact to="/" onClick={(ev) => onLinkClick(ev.target.id)}>Home</NavLink>
                            <NavLink id='2' className='footer-link' to="/chi-siamo" onClick={(ev) => onLinkClick(ev.target.id)}>{t('chiSiamo')}</NavLink>
                            <NavLink id='3' className='footer-link' to="/prodotti" onClick={(ev) => onLinkClick(ev.target.id)}>{t('prodotti')}</NavLink>
                            <NavLink id='4'className='footer-link' to="/qualita" onClick={(ev) => onLinkClick(ev.target.id)}>{t('qualita')}</NavLink>
                            <NavLink id='5' className='footer-link' to="/contattaci" onClick={(ev) => onLinkClick(ev.target.id)}>{t('contattaci')}</NavLink>
                        </div>

                    </Col>
                </Row>

                <Row className='copyright-row pt-1'>
                    <Col>
                        <p>
                            &#169; Copyright Veneziano s.r.l. {t('copyright')}.
                            <br /> {`${t('ispirato')} `}
                            <a href="https://bootstrapmade.com/day-multipurpose-html-template-for-free/"
                                target="_blank"
                                rel='noopener noreferrer'
                                className='reference-link'
                            >
                                <strong>Day Template</strong>
                            </a>
                            {` ${t('ideato')} `}
                            <strong id='bootstrapMade'>BootstrapMade</strong>.
                        </p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer