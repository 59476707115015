import React from 'react'

//components
import ContainerBarattolo from './ContainerBarattolo'

//data sheets ita
import c1_ita from '../assets/barattoli/schede/100_it.xls'
import c2_ita from '../assets/barattoli/schede/200_it.xls'
import c3_ita from '../assets/barattoli/schede/220_it.xls'
import c4_ita from '../assets/barattoli/schede/300_it.xls'
import c5_ita from '../assets/barattoli/schede/500_it.xls'
import c6_ita from '../assets/barattoli/schede/2500_it.ods'
import c7_ita from '../assets/barattoli/schede/3000_it.ods'
import c8_ita from '../assets/barattoli/schede/5000_it.ods'

//data sheets eng
import c1_en from '../assets/barattoli/schede/100_en.xls'
import c2_en from '../assets/barattoli/schede/200_en.xls'
import c3_en from '../assets/barattoli/schede/220_en.xlsx'
import c4_en from '../assets/barattoli/schede/300_en.xls'
import c5_en from '../assets/barattoli/schede/500_en.xls'
import c6_en from '../assets/barattoli/schede/2500_en.ods'
import c7_en from '../assets/barattoli/schede/3000_en.ods'
import c8_en from '../assets/barattoli/schede/5000_en.ods'

const ListaBarattoli = (props) => {

    const pickCertificate = (id) => {
        let certificate
        switch(id){
            case '1':
                certificate = props.lang === 'it' ? c1_ita : c1_en
                break;
            case '2':
                certificate = props.lang === 'it' ? c2_ita : c2_en 
                break;
            case '3':
                certificate = props.lang === 'it' ? c3_ita : c3_en
                break;
            case '4':
                certificate = props.lang === 'it' ? c4_ita : c4_en
                break;
            case '5':
                certificate = props.lang === 'it' ? c5_ita : c5_en
                break;
            case '6':
                certificate = props.lang === 'it' ? c6_ita : c6_en
                break;
            case '7':
                certificate = props.lang === 'it' ? c7_ita : c7_en
                break;
            case '8':
                certificate = props.lang === 'it' ? c8_ita : c8_en
                break;
            default:
        }
        return certificate
    }
    const listaContainer = props.barattoli.map((barattolo) => {
        return (
            <ContainerBarattolo
                key={barattolo.id}
                name={barattolo.name}
                diameter={barattolo.diameter}
                height={barattolo.height}
                sheet={pickCertificate(barattolo.id)}
                sheetName={barattolo.datasheet}
                image={barattolo.image}
            />
        )
    })

    return listaContainer

}

export default ListaBarattoli