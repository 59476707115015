import React from 'react'

//font awesome
import { faCompass } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// translator
import { useTranslation } from 'react-i18next'

import { motion } from 'framer-motion'

const DirectionsLink = () => {

    const { t } = useTranslation()

    return (
        <motion.a
            className='directions-button'
            href='https://goo.gl/maps/9UJtAWDDsdv6QDzUA'
            target="_blank"
            rel='noopener noreferrer'
            whileHover={{ scale: 1.1 }}
        >
            <FontAwesomeIcon icon={faCompass} className='mr-2' size='2x' /><br />
            {t('indicazioni')}
        </motion.a>
    )
}

export default DirectionsLink