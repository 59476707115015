import Barattolo from './Barattolo'

const sendEmail = (emailData) => {

    return new Promise((resolve, reject) => {
        fetch(
            '/api/email',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(emailData)
            }
        )
            .then((response) => {
                if (response.ok)
                    resolve('Correctly sent')
                else {
                    // analyze the cause of error
                    console.log(response)
                    response.json()
                        .then((obj) => {
                            // error msg in the response body
                            console.log(obj)
                            reject(obj);
                        })
                        .catch((err) => {
                            // something else
                            console.log('Cannot parse server response')
                            reject({ errors: [{ param: "Application", msg: "Cannot parse server response" }] })
                        });
                }
            })
            .catch((error) => {
                reject('porco zio')
            })
    })
}

const getBarattoli = () => {
    return new Promise((resolve, reject) => {
        fetch(
            `/api/barattoli`,
            {
                method: 'GET',
            }
        )
            .then((response) => {
                if (!response.ok)
                    reject('error')
                else {
                    response.json()
                        .then((barattoli) => {
                            const listaBarattoli = barattoli.map(barattolo => Barattolo.fromJson(barattolo))
                            resolve(listaBarattoli)
                        })
                }
            })
            .catch((error) => reject(error))
    })
}

const API = { sendEmail, getBarattoli }

export default API